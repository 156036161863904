import { Card } from 'antd';
import React from 'react'
import { QrReader } from 'react-qr-reader';

function EventQrReader({ onRead }) {
  return (
    <Card bordered={false} bodyStyle={{ padding: 0}}>
      <QrReader
        constraints={{
          facingMode: 'environment'
        }}
        onResult={(result, error) => {
          if (!!result) {
            onRead(result?.text);
          }

          if (!!error) {
            // console.info(error);
          }
        }}
      />
    </Card>
  )
}

export default EventQrReader