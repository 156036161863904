import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'
import { statusSlice } from '../../app/reducers/status';
import { ServiceHelper } from '../../utils/service-helper';
import BasePage from '../core/base-page';
import { CardContent, Container, Grid, Link, Typography, Chip, Stack } from '@mui/material';
import GuestTypography from '../../components/guest-typography';
import { Badge, Card, Descriptions, Divider, Image, QRCode, Button, Tabs } from 'antd';
import LogoYatay from '../../assets/images/logo-yatay.png';
import { LoginOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { InfoCircleOutlined } from '@ant-design/icons';

function GuestProfile() {
  const { guidId } = useParams();
  const [guestInfo, setGuestInfo] = useState({});
  const [eventList, setEvents] = useState();
  const [selectedFilter, setSelectedFilter] = useState("Beyler");
  const dispatch = useDispatch();
  const [link, setLink] = useState("");
  console.log('Params: ', guidId);

  // reset login status
  useEffect(() => {
    if (!guidId) {
      dispatch(statusSlice.actions.setStatus({ message: 'Misafir kaydı bulunamadı', type: 'warning', status: true }))
    } else {
      getGuestByGuidId(guidId)
    }
  }, []);

  useEffect(() => {
    getAllEvents();
  }, []);

  async function getAllEvents() {
    const organizationId = 5;
    const response = await ServiceHelper.get(`/event/getAll/${organizationId}/${2}`);
    setEvents(response.data.result);
    // console.log('guidId', response);
  }

  const getGuestByGuidId = async (guidId) => {
    const response = await ServiceHelper.get(`/guest/getByGuid/${guidId}`);
    if (response.success) {
      const { result } = response.data;
      if (!result) {
        dispatch(statusSlice.actions.setStatus({ message: 'Misafir Bilgileri bulunamadı', type: 'warning', status: true }))
      } else {
        setGuestInfo(result);
        // generateQRCode(guidId, result.organizationId)
        const linkValue = ServiceHelper.getUrl(`guest/profile/${result.organizationId}/${guidId}`)
        // const linkValue = ServiceHelper.getUrl(`admin/tracker/${result.organizationId}/${guidId}`);
        setLink(linkValue)
        dispatch(statusSlice.actions.setStatus({ message: 'Misafir Bilgileri getrildi', type: 'success', status: true }))
      }
    } else {
      dispatch(statusSlice.actions.setStatus({ message: 'Hata Oluştu', type: 'error', status: true }))
    }
  }

  const { firstName, lastName, phoneNumber, provionce } = guestInfo;

  const formatPhoneNumber = (phoneNumberString) => {
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '0 (' + match[1] + ') ' + match[2] + ' ' + match[3];
    }
    return null;
  }
  const getAllEventsByEventGroup = async (eventGroup) => {
    const organizationId = 5;
    const response = await ServiceHelper.get(`/event/getAll/${organizationId}/${eventGroup}`);
    setEvents(response.data.result);
  }

  const getEventTime = (start, end) => {
    return <Stack direction={'column'}>
      <div>{dayjs(start).format('HH:mm')}</div>
      <div>{dayjs(end).format('HH:mm')}</div>
    </Stack>
  }
  const filterTimeline = async (type) => {
    switch (type) {
      case "Beyler":
        setSelectedFilter("Beyler");
        getAllEventsByEventGroup(2);
        break;
      case "Hanımlar":
        setSelectedFilter("Hanımlar");
        getAllEventsByEventGroup(3);
        break;
      default:
        break;
    }
  }
  // this gives an object with dates as keys
  const groups = eventList?.reduce((groups, event) => {
    console.log("Event Date", event.startDate);
    // const date = event.startDate.split('T')[0];
    const date = "3 Şevval 1445 | 12 Nisan 2024";
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(event);
    // console.log(groups);
    return groups;
  }, {});
  // Edit: to add it in the array format instead
  const groupArrays = groups && Object.keys(groups).map((date) => {
    return {
      date,
      events: groups[date]
    };
  });

  const getEventGroupChip = (eventGroup) => {
    let event;
    switch (eventGroup) {
      case 2:
        event = <Chip label={"Beyler"} color="success" size="small" />
        break;
      case 3:
        event = <Chip label={"Hanımlar"} color="error" size="small" />
        break;
      default:
        break;
    }

    return event;
  }

  const timeLine = (eventList) => {

    return (
      <Timeline sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.2,
        },
      }}>
        {
          eventList?.map((event) =>
            <TimelineItem key={event.id}>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                {getEventTime(event.startDate, event.endDate)}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color='info' />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ m: 'auto 0' }}
              >
                {/* <Typography variant="h6" component="span">
                {event.name}
              </Typography> */}
                <Stack direction="row" spacing={1}>
                  {getEventGroupChip(event.eventGroup)}
                  <Typography variant="subtitle1">{event.name}</Typography>
                  {/* <Button type='link' icon={<InfoCircleOutlined />} onClick={() => {
                    // navigate('/guest/event-detail', { state: { event: event } });
                  }}>
                  </Button> */}
                </Stack>
              </TimelineContent>
            </TimelineItem>
          )
        }
      </Timeline>
    );
  }

  return (
    <BasePage maxWidth={'sm'}>
      {
        guestInfo && (
          <Container maxWidth="sm" sx={{}} style={{ padding: 8, justifyContent: 'center' }}>

            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Divider plain>Katılımcı Bilgileri</Divider>
                  <Grid item sm={6} xs={6} alignItems={'center'} display={'flex'} flexDirection={'column'}>
                    <Typography color={'GrayText'} variant='caption'>Adı</Typography>
                    <Typography variant='body1'>{firstName}</Typography>
                  </Grid>
                  <Grid item sm={6} xs={6} alignItems={'center'} display={'flex'} flexDirection={'column'}>
                    <Typography color={'GrayText'} variant='caption'>Soyadı</Typography>
                    <Typography variant='body1'>{lastName}</Typography>
                  </Grid>
                  <Grid item sm={6} xs={6} alignItems={'center'} display={'flex'} flexDirection={'column'}>
                    <Typography color={'GrayText'} variant='caption'>Telefonu</Typography>
                    <Typography variant='body1'>{formatPhoneNumber(phoneNumber)}</Typography>
                  </Grid>
                  {/* <Grid item sm={6} xs={6} alignItems={'center'} display={'flex'} flexDirection={'column'}>
                    <Typography color={'GrayText'} variant='caption'>Katıldığı İl</Typography>
                    <Typography variant='body1'>{provionce}</Typography>
                  </Grid> */}
                  <Divider plain>Paylaşım Kodu</Divider>
                  <Grid item sm={12} xs={12} alignItems={'center'} display={'flex'} flexDirection={'column'}>
                    <QRCode errorLevel="H" value={link} size={248} />
                  </Grid>
                </Grid>
                <Divider plain>Program Akışı</Divider>
                <Stack >
                  <Chip label={"Beyefendiler - Şah Sultan Külliyesi"} color={selectedFilter == "Beyler" ? "warning" : "default"} size="medium" clickable onClick={() => { filterTimeline('Beyler') }} />
                </Stack>
                <Stack>
                  <Chip label={"Hanımefendiler - Mustafa Selami Efendi Tekkesi"} color={selectedFilter == "Hanımlar" ? "warning" : "default"} size="medium" clickable onClick={() => { filterTimeline('Hanımlar') }} />
                </Stack>
                {/* <Divider /> */}
                <Tabs
                  style={{ margin: 8 }}
                  // onChange={onChange}
                  type="card"
                  items={groupArrays?.map((item, i) => {
                    const id = String(i + 1);
                    return {
                      // label: `${dayjs(item.date).locale('tr').format('dddd')}`,
                      label: item.date,
                      key: id,
                      children: timeLine(item.events),
                    };
                  })}
                />

              </CardContent>
            </Card>
          </Container>

        )
      }

    </BasePage>
  )
}

export default GuestProfile